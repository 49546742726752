import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <section>
      <div className="container-fluid px-0 fixed-top">
        <nav
          className="navbar navbar-expand-lg "
          style={{ backgroundColor: "#fff", zIndex: "999" }}
        >
          <Link to="/">
            <img
              style={{ cursor: "pointer" }}
              className="headerlogo"
              src="./assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ml-4 jhrjfrffrfr">
                <a href="#feature">Features</a>
              </li>
              <li className="nav-item ml-4 jhrjfrffrfr">
                <a href="#howitworks">How It Works</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* <nav className="navbar navbar-expand-lg navbar-light mx-5 pt-4">
          <div className="d-flex align-items-center" />

          {/* <button className="navbar-toggler ml-auto " type="button">
            <span className="navbar-toggler-icon"></span>
          </button> */}
      <div className="ml-auto " />
      {/* </nav> */}
    </section>
  );
}

export default Header;
