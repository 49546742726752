import React from "react";
import HeroSection from "../components/HeroSection";
import Privatesecurewallet from "../components/Privatesecurewallet";
import Conversation from "../components/Conversation";
import Steps from "../components/Steps";
import Rewards from "../components/Rewards";
import StoreSend from "../components/StoreSend";

function Home() {
  return (
    <div>
      <HeroSection />
      <StoreSend />
      <Conversation />
      <Privatesecurewallet />
      <Steps />
      <Rewards />
    </div>
  );
}

export default Home;
