import React from "react";

export default function Conversation() {
  return (
    <div className="position-relative">
      <div className="container">
        <div className="row mian-spacing-002">
          <div className="col-lg-6 order-lg-2 order-1 d-flex align-items-center trhytjhytjhytjytjyt">
            <div>
              <h3 className="cashii-main-heading text-start">
                Store, Send, Receive Crypto
              </h3>
              <p className="cashii-main-text text-start">
                Sending and receiving cryptocurrency through Cashii can offer a
                secure way to transfer funds without the need for
                intermediaries. This can provide a level of transparency in
                transactions that traditional banking systems might not offer.
              </p>
            </div>
          </div>
          <div className="col-lg-6 order-lg-2 order-2">
            <div className="position-relatvie">
              <div className="d-flex justify-content-center edwjm65yt6">
                <img
                  style={{ cursor: "pointer" }}
                  className="for-rotating"
                  src="./assets/img/group2.png"
                  alt="Logo"
                />
                <img
                  className="main-hero-image-0090"
                  src="./assets/img/sec3.png"
                  alt="Logo"
                />
                <img
                  className="main-hero-image-0092"
                  src="./assets/img/sec4.png"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
