import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./routes/ProtectedRoute";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import TermsUse from "./pages/TermUse";

function App() {
  return (
    <BrowserRouter>
      <Toaster position="bottom-center" />
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/termsandconditions" element={<TermsUse />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
