import React from "react";

function Steps() {
  return (
    <div>
      <div className="container pt-5">
        <div className="pasndejiub876t6">
          <div id="howitworks" className="row mt-5">
            <div className="d-flex justify-content-center">
              <div>
                <h3 className="cashii-main-heading">3 Simple Steps</h3>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    className=""
                    src="./assets/img/sim1.png"
                    alt="Logo"
                  />
                  <h4 className="secsteps-heading pt-3">Download App</h4>
                  <p className="taecxfeofkewuhfbhwe">
                    Download Cashii App into your phone.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    className=""
                    src="./assets/img/sim2.png"
                    alt="Logo"
                  />
                  <h4 className="secsteps-heading pt-3">Create or import</h4>
                  <p className="taecxfeofkewuhfbhwe">
                    Create new wallet or import a wallet
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    className=""
                    src="./assets/img/sim3.png"
                    alt="Logo"
                  />
                  <h4 className="secsteps-heading pt-3">Manage Crypto</h4>
                  <p className="taecxfeofkewuhfbhwe">
                    Send, Receive & Store your Crypto
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;
