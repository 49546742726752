import React from "react";
import { Link } from "react-router-dom";

const index = () => (
  <section className="footer-bg">
    <div className="container ">
      <div className="row">
        <div className="col-lg-6 ybatbdbhjdhd-001 footer-spacing">
          <div className="d-flex align-items-start iuhrhrkjngvkj-00099">
            <img
              style={{ cursor: "pointer" }}
              className="headerlogo22"
              src="./assets/img/logo.svg"
              alt="Logo"
            />
          </div>
          <div className="d-flex pt-4">
            <div>
              <a target="blank" href="https://www.twitter.com/cashiime">
                <img
                  style={{ cursor: "pointer" }}
                  className="headerlogo"
                  src="./assets/img/twitter.png"
                  alt="Logo"
                />
              </a>
            </div>

            <div className="pl-4">
              <a target="blank" href="https://t.me/cashiime">
                <img
                  style={{ cursor: "pointer" }}
                  className="headerlogo"
                  src="./assets/img/telegram.png"
                  alt="Logo"
                />
              </a>
            </div>
            <div className="pl-4">
              <a
                target="blank"
                href="https://www.linkedin.com/company/cashii-me/"
              >
                <img
                  style={{ cursor: "pointer" }}
                  className="headerlogo"
                  src="./assets/img/lonkdin.png"
                  alt="Logo"
                />
              </a>
            </div>
            <div className="pl-4">
              <a target="blank" href="https://www.instagram.com/cashii.me">
                <img
                  style={{ cursor: "pointer" }}
                  className="headerlogo"
                  src="./assets/img/insta.png"
                  alt="Logo"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="col-lg-6 d-flex justify-content-end footer-spacing">
          <div>
            <h3 className="footer-heading-00">Contact</h3>
            <p className="footer-link">
              <span className="pr-3">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
                    fill="#191919"
                  />
                </svg>
              </span>
              <a className="foootermaill" href="mailto:support@cashii.me">
                support@cashii.me
              </a>
            </p>
          </div>
        </div>
        <div className="col-lg-6 ybatbdbhjdhd-001">
          <p className="footer-link">Copyrights © 2024, All Rights Reserved</p>
        </div>
        <div className="col-lg-6 ydfebjfjfe-000fd">
          <div className="d-flex justify-content-end nkrfjgvrgbg">
            <p className="footer-link">
              <Link to="/termsandconditions" target="_blank">
                {" "}
                Terms & Conditions{" "}
              </Link>
              |{" "}
              <Link to="/privacy" target="_blank">
                {" "}
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default index;
