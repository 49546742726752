import React from "react";

function StoreSend() {
  return (
    <div className="position-relative">
      <img
        className="main-hero-image-004"
        src="./assets/img/sec2.png"
        alt="Logo"
      />
      <img
        className="main-hero-image-005"
        src="./assets/img/sec3.png"
        alt="Logo"
      />
      <img
        className="main-hero-image-006"
        src="./assets/img/sec4.png"
        alt="Logo"
      />
      <div className="container">
        <div className="row  mian-spacing-002">
          <div className="col-lg-6 order-lg-1 order-2">
            <div className="position-relatvie">
              <div className="d-flex formobileimage476r8743 justify-content-center">
                <img
                  style={{ cursor: "pointer" }}
                  className="for-rotating"
                  src="./assets/img/group1.png"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
          <div
            id="feature"
            className="col-lg-6 order-lg-2 order-1 d-flex align-items-center tyeiuyfef87766"
          >
            <div className="jniubgg874843tr7gf5">
              <h3 className="cashii-main-heading text-start">
                Unlock Daily Rewards
              </h3>
              <p className="cashii-main-text text-start">
                Earning daily rewards in the Cashii wallet can be a great way to
                maximize the benefits of your transactions. By receiving cashii
                points and Airdrop rewards. Cashii wallet not only incentivizes
                regular usage but also provides added value for your loyalty to
                the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreSend;
