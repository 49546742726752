import React from "react";

function Privacy() {
  return (
    <div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="container trhtrhtrh5tjh5j7j76j76j6jy"
      >
        <div className="row">
          <h2 className="footer-heading-00"> Privacy Policy</h2>
          <p className="footer-link">
            Last Updated: November 20, 2023 This policy (the “Privacy Policy”)
            sets out the privacy practices of Yato Inc. (“Cashii”, “we”, “us” or
            “our”), a corporation formed pursuant to the Ontario Business
            Corporations Act (R.S.O. 1990, CHAPTER B.16). Cashii is registered
            with the Financial Transactions and Reports Analysis Centre of
            Canada (“FINTRAC”) as a Money Service Business (MSB), dealing in
            virtual currencies and money transferring (#M23869060).
          </p>
          <div className="col-lg-12 mt-4">
            <h2 className="footer-heading-00">Introduction</h2>
            <p className="footer-link">
              This Privacy Policy is effective as of November 20, 2023. This
              Privacy Policy replaces any previous notices from Cashii about
              personal information collection, protection, use, sharing,
              retention, and privacy practices and applies to the use, sharing,
              and retention of personal information previously collected.
              However, this Privacy Policy does not supersede any application or
              account specific terms and conditions you may have agreed to.
              Those terms must be read together with this Privacy Policy. We may
              amend this Privacy Policy at any time, and if the changes are
              significant, a notice of revision will be sent to you via email.
              It is your responsibility to review this Privacy Policy from time
              to time to be aware of any such changes. Please check back
              regularly for any changes and to ensure you are always informed of
              the personal information that we collect about you, how we use it,
              and the circumstances under which we may disclose it. Cashii is
              committed to keeping your personal information in our possession
              or under our control in a confidential, accurate, secure and
              private manner. This Privacy Policy explains how we fulfill such
              commitments, including the manner in which Cashii collects, uses,
              discloses and otherwise treats (collectively “Processing”) your
              personal information made available to us through our mobile app
              and our website at https://cashii.me. This includes any other
              interactions with you. We have organized the information into
              sections which correspond with the ten privacy principles outlined
              in Schedule 1 of the Personal Information Protection and
              Electronic Documents Act (Canada), which statute, in addition to
              certain provincial statutes, governs the collection, use and
              disclosure of personal information in the course of commercial
              activities in Canada. This Privacy Policy may be updated
              periodically to reflect changes to our personal information
              practices. We strongly encourage you to refer to this Privacy
              Policy often for the latest information about our personal
              information practices related to your privacy.
            </p>
          </div>
          <div className="col-lg-12 mt-4">
            <h2 className="footer-heading-00">Accountability</h2>
            <p className="footer-link">
              Cashii is responsible for personal information under its control.
              1.1 Accountability for Cashii compliance with the principles rests
              with the senior management of Cashii and the person or persons
              designated by senior management as Privacy Officer, even though
              other individuals within the organization may be responsible for
              the day-to-day collection and processing of personal information.
              In addition, other individuals within the organization may be
              delegated to act on behalf of senior management or the Privacy
              Officer. 1.1 Cashii senior management has designated a Privacy
              Officer to oversee the organization’s compliance with these
              privacy principles. Submissions to the Privacy Officer may be made
              in writing via email at cashii.compliance@gmail.com. 1.2 To
              protect your personal information, Cashii has put in practice the
              following; (a) Company wide policies and procedures to protect
              client personal information. (b) Procedures on how to respond to
              client questions and complaints related to their personal
              information. (c) Strategies to inform clients and employees on how
              Cashii protects, collects, uses and discloses personal
              information, including this Privacy Policy. (d) Employee training
              on the policies and procedures referenced in subparagraphs (a) and
              (b) above.
            </p>
          </div>
          <div className="col-lg-12 mt-4">
            <h2 className="footer-heading-00">Identifying Purposes</h2>
            <p className="footer-link">
              Identifying Purposes Cashii will identify the purposes for which
              personal information is collected at or before the time the
              information is collected. 2.1 Cashii collects personal information
              for the following primary purposes (“identified purposes”): (a) to
              provide our products and services, including to open and service
              your account and to verify your account and your identity; (b) to
              be able to facilitate electronic fund transfers to and from your
              account; (c) to maintain commercial relations and to communicate
              with you about your Cashii products and services; (d) to identify
              customer needs and/or preferences and deliver products and
              services that meet them; (e) to comply with legal and regulatory
              requirements; (f) to collect amounts owing to us; and (g) to
              administer and manage our business operations. 2.2 When personal
              information that has been collected is to be used for a purpose
              not previously identified, the new purpose will be identified
              prior to use. Unless the new purpose is required by law, Cashii
              will obtain your consent before information is used for that new
              purpose. 2.3 Cashii collects some user information using
              third-party analytics platforms. These platforms include but are
              not limited to Google Analytics. These platforms help us
              understand how users use the Cashii platform and services and
              allows us to enhance our offerings based on relevant user activity
              and related data. 2.4 Occasionally, we may send you emails and
              other communications about products and services that we think may
              be of interest to you. You can unsubscribe from these
              communications at any time by using the unsubscribe mechanisms
              provided in these communications. Please note that unsubscribing
              will not unsubscribe you from account or transactional documents
              which are delivered to you for non-marketing purposes. These
              controls have been implemented to comply with Canadian anti-spam
              legislation.
            </p>
          </div>
          <div className="col-lg-12 mt-4">
            <h2 className="footer-heading-00">Consent</h2>
            <p className="footer-link">
              Except where otherwise permitted or required by law, we will
              obtain your informed consent to collect and retain your personal
              information. Express consent may be obtained verbally,
              electronically or in writing. Implied consent may be obtained
              through your use of a product or service, or when you approach us
              to obtain information, inquire about or apply for products or
              services from us. Cashii will seek express consent when the
              information is likely to be considered sensitive. In certain
              circumstances, personal information can be collected, used, or
              disclosed without the knowledge and consent of the individual. For
              example, legal, medical or security reasons may make it impossible
              or impractical to seek consent. When information is being
              collected for the detection and prevention of fraud or for law
              enforcement, seeking the consent of the individual might defeat
              the purpose of collecting the information. Moreover, Cashii may
              provide personal information to its lawyer or agent to collect a
              debt, comply with a subpoena, warrant or other court order,
              government institution requesting the information upon lawful
              authority, or as may be otherwise required by law. We may disclose
              your personal information to law enforcement or other regulatory
              agencies such as the Financial Transactions and Reports Analysis
              Centre (“FINTRAC”) based on regulatory and legal requirements
              applicable to Cashii. 3.1 Cashii will generally seek consent for
              the use or disclosure of the information at the time of
              collection. In certain circumstances, consent with respect to use
              or disclosure may be sought after the information has been
              collected but before use (for example, when Cashii wants to use
              information for a purpose not previously identified to you). 3.2
              Cashii will not, as a condition of the supply of a product or
              service, require an individual to consent to the collection, use,
              or disclosure of information beyond that required to fulfill the
              explicitly specified and legitimate purposes. 3.3 An individual
              may withdraw consent at any time, subject to legal or contractual
              restrictions and reasonable notice. Cashii will inform the
              individual of the implications of such withdrawal. In order to
              withdraw consent, an individual must provide notice to Cashii in
              writing by contacting the Privacy Officer through email at
              cashii.compliance@gmail.com. 3.4 With respect to personal
              information already collected by Cashii prior to the publication
              of this Privacy Policy, this Policy will constitute reasonable
              notice to Cashii current customers and employees of the purposes
              and uses for which such personal information has been collected.
              Should an individual object to these ongoing uses or disclosures,
              consent may be withdrawn upon providing notice to Cashii in
              writing and is subject to any legal requirements.
            </p>
          </div>
          <div className="col-lg-12 mt-4">
            <h2 className="footer-heading-00">Limiting Collection</h2>
            <p className="footer-link">
              The collection of personal information will be limited to that
              which is necessary for the purposes identified by Cashii,
              including under “Identifying Purposes”. Information will be
              collected by fair and lawful means. 4.1 The personal information
              that we may collect from you includes: (a) contact information
              such as your full legal name, residential address, email address,
              date of birth, occupation, telephone number, government
              identification or special categories of data (such as biometric
              information; (b) During the sign-up process of your account, and
              to help us follow FINTRAC identity verification rules, we may use
              a biometric system to verify your identity. As part of this
              process, we may require a photo of your government-issued photo
              identification, a selfie, and a video of your face. If you use
              this identity verification method, you hereby consent to the
              collection, use and retention of your biometric information, and
              you understand that your biometric information will solely be used
              to confirm your identity. (c) identifying information such as a
              screen name, account ID, or device ID; (d) technical diagnostic
              data such as crash logs; (e) information about your past purchases
              of or using Cashii products and services; (f) information about
              how you interact with our platforms, including taps, clicks, and
              similar information; (g) information about the advertisements you
              have seen using our products and services; (h) your approximate
              location; (i) details related to your net worth, income, risk
              tolerance and/or crypto/investment experience; (j) other
              information that we may need in order to provide you with a
              product or service; and (k) other information with your consent,
              or as permitted or required by law; and 4.2 We may also use your
              personal information to confirm your identity with TransUnion of
              Canada, Inc., Equifax Inc. and their related and affiliated
              entities (the “Credit Agencies”). To confirm your identity, we use
              personal information you provide us, for example, your name and
              date of birth, to obtain reports and information from the Credit
              Agencies about you. We cross reference the information obtained
              from the Credit Agencies about you with the information you
              provide us. To access your personal information on file with the
              Credit Agencies, for the purpose of confirming your identity and
              matching it with other personal information you provide to us, we
              may use a third party service provider. 4.3 Cashii may use a
              ‘Cookie’ to collect certain information which it uses to track
              user patterns on its platforms. A Cookie is a text file containing
              a unique identification number that identifies a user’s browser,
              but not a particular individual. Furthermore, Cashii does not use
              Cookies in combination with other information to obtain personally
              identifiable information. If an individual does not wish to use
              Cookies, he or she can reset their browser to either provide
              notification of or refuse to accept Cookies. 4.4 When you visit
              Cashii, third-parties may place Cookies on your browsers for
              targeted advertising purposes. If you wish to opt out of
              third-party advertising cookie tracking, please visit
              www.aboutads.info or networkadvertising.org/choices. Limiting Use,
              Disclosure, and Retention Personal information will not be used or
              disclosed for purposes other than those for which it was
              collected, except with the consent of the individual or as
              permitted or required by law. Personal information will be
              retained only as long as necessary for the fulfillment of those
              purposes, except as otherwise permitted or required by law. 5.1
              Cashii may disclose your personal information to certain third
              parties, including to: (a) a service provider involved in the
              development, promotion, marketing or enhancement of Cashii
              products or services and that has agreed, by contract or
              otherwise, to treat your personal information in accordance with
              this Privacy Policy or an equivalent level of protection; (b) a
              credit collections agency; (c) emergency services in an emergency
              situation; (d) other organizations or agents with whom you have
              arrangements and to whom you have provided consent directly; and
              (e) any other third-party, upon receiving your consent or as
              permitted or required by law. 5.2 Cashii will retain personal
              information for only as long as required to fulfill the identified
              purposes or as required by law. Personal information that is no
              longer required to fulfill the identified purposes will be
              destroyed, erased or made anonymous according to the guidelines
              and procedures established by Cashii. 5.3 Cashii’s computer
              systems run partially on cloud-based systems operated by other
              companies in the United States, Canada, and elsewhere. Your
              personal information may be disclosed to our providers, who are
              contractually bound to treat all information confidentially, as
              part of day-to-day business operations. We attempt to make use of
              services that encrypt information. As such, you acknowledge that
              your personal information may be accessible to governmental,
              national security and regulatory authorities in accordance with
              the laws of these jurisdictions. Accuracy Cashii ensures that
              personal information will be as accurate, complete, and up-to-date
              as is necessary for the purposes for which it is to be used,
              including to minimize the possibility that inappropriate
              information may be used to make a decision about you. 6.1 You have
              the ability to correct or change your personal information in our
              records, such as your address and contact information. Certain
              information can be updated within your Cashii account once you
              have successfully logged in. You may change this information at
              any time and as often as necessary. If you need assistance or have
              questions about changing, updating or correcting information, you
              can contact us by submitting a request here. Should there be any
              other information that needs to be updated but cannot be completed
              within your Cashii account, you may contact Cashii’s Privacy
              Officer through email at cashii.compliance@gmail.com. Data
              Protection Controls and Security Measures Cashii protects personal
              information against loss, theft, unauthorized access, disclosure,
              copying, use, or modification using security safeguards
              appropriate to the sensitivity of the information. The nature of
              the safeguards varies depending on the sensitivity of the
              information that has been collected, the amount, distribution and
              format of the information, and the method of storage. 7.1 Methods
              that Cashii uses to protect personal information include: (a)
              physical measures, such as locked filing cabinets and restricted
              access to offices; (b) organizational measures, such as security
              clearances and limiting access on a “need to know” basis; and (c)
              technological measures, such as the use of passwords and
              encryption. 7.2 Cashii employees and contractors are made aware of
              the importance of maintaining the confidentiality of personal
              information. All employees and contractors are governed by a
              non-disclosure agreement prohibiting disclosure or use of any
              confidential or personal information for any purposes other than
              the stated business purposes. 7.3 Cashii uses care in the disposal
              or destruction of personal information to prevent unauthorized
              parties from gaining access to the information. Openness You may
              request access to more information about our privacy practices,
              and we will make it available to you in a manner that is easy to
              understand, including via this Privacy Policy. Such information
              may include the means of gaining access to personal information
              held by Cashii and a description of the types of information held
              by Cashii or our subsidiaries, including a general account of its
              use. Individual Access Subject to certain exceptions prescribed by
              law, upon written request, we will inform you of the existence,
              use, and disclosure of your personal information and will provide
              you with access to that information within 30 days or such other
              time period as may be permitted by applicable laws. You are
              entitled to challenge the accuracy and completeness of your
              personal information, such as your address and contact
              information, and to amend it or have it amended as appropriate.
              All such requests can be sent to Cashii’s Privacy Officer through
              email at cashii.compliance@gmail.com. 9.1 The process for changing
              information begins in the password protected area of your Cashii
              account. You may change this information at any time and as often
              as necessary. If assistance is needed, or for answers to questions
              about changing, updating or correcting information, please contact
              Cashii through email at cashii.compliance@gmail.com. 9.2 When an
              individual successfully demonstrates the inaccuracy or
              incompleteness of personal information, Cashii will amend the
              information as required. Where appropriate, the amended
              information will be transmitted to third-parties having access to
              the information in question. 9.4 When a challenge is not resolved
              to the satisfaction of the individual, Cashii will record the
              substance of the unresolved challenge. When appropriate, the
              existence of the unresolved challenge will be transmitted to third
              parties having access to the information in question. Challenging
              Our Privacy Policy If you have questions or concerns about your
              personal information, please contact Cashii’s Privacy Officer by
              using the contact information provided under “Accountability”. If
              your concern remains unresolved by us, you may contact the Privacy
              Commissioner of Canada at: Office of the Privacy Commissioner of
              Canada 30 Victoria Street Gatineau, Quebec K1A 1H3 Toll-free:
              1-800-282-1376 Phone: (819) 994-5444 Fax: (819) 994-5424 Internet:
              www.priv.gc.ca
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
