import React from "react";

function TermsUse() {
  return (
    <div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="container trhtrhtrh5tjh5j7j76j76j6jy"
      >
        <h2 className="footer-heading-00">Terms of Use</h2>
        <p className="footer-link">
          Last updated: November 15, 2023 Yato Inc. (Cashii,we, us or our) is a
          corporation formed pursuant to the Ontario Business Corporations Act
          (R.S.O. 1990, CHAPTER B.16). Cashii is registered with the Financial
          Transactions and Reports Analysis Centre of Canada (FINTRAC) as a
          Money Service Business (MSB), dealing in virtual currencies and money
          transferring (#M23869060). In consideration for permitting your access
          to our website and online services and other good and valuable
          consideration, you agree to the following Terms of Use.
        </p>
        <div className="row jnverknevkjnnbkbfordesktop">
          <div className="col-lg-12">
            <h2 className="footer-heading-00">Introduction</h2>
            <p className="footer-link">
              These terms, including our Privacy Policy, any schedule, annexe or
              addenda incorporated herein by reference, or any terms referencing
              these terms (collectively, the “Terms”) apply to your access and
              use of the Services (defined below) as well as your visits on our
              main website (https://cashii.me/) or any other website maintained
              by Cashii (collectively, the “Website”) Please read the Terms
              carefully as they govern your use of the Services and your visits
              on the Website. If you do not agree with any term and condition
              included in the Terms, please do not visit, access or use the
              Services or the Website. By clicking the “I agree with the Terms
              of Use and Privacy Policy” checkbox displayed on the sign-up page,
              by downloading, accessing or using the Services, by visiting our
              Website, or by accepting any set of terms referencing these Terms,
              you acknowledge that you have read, understood and accepted the
              Terms (as they may be amended from time to time). We may amend the
              Terms at any time by posting a revised version of the Terms on the
              Website, the mobile or web application, or by providing a copy
              directly to you via email, hyperlink or otherwise. It is your
              responsibility to check the Terms periodically for changes, even
              though we will strive to provide you with a notice of the amended
              Terms. The amended Terms will become effective as of the time they
              are posted, but will not apply retroactively. Your continued use
              of the Services, or your visit to our Website, after the posting
              of the amended Terms constitutes your acceptance of such amended
              Terms, unless a different acceptance mechanism is expressly
              provided. If you do not agree with any amendment, your sole and
              exclusive remedy is to discontinue using the Services or visiting
              the Website.
            </p>
          </div>
          <div className="col-lg-12">
            <h2 className="footer-heading-00">Cashii Services</h2>
            <p className="footer-link">
              Cashii provides various financial services accessible through and
              based on Internet and/or blockchain technologies, and offered via
              our Website, our mobile or web application, or by other means made
              possible by technological advancements (the Services). The
              Services include but are not limited to a proprietary platform to
              buy and/or sell various cryptocurrencies (Crypto Assets), to hold,
              transfer or withdraw various fiat currencies (“Fiat”), to send or
              receive Crypto Assets, to use Crypto Assets to buy and sell
              various products and services through participating merchants
              (Merchants), to earn income from deposited Crypto Assets and to
              offer and receive peer-to-peer Crypto Asset loans. The Crypto
              Assets available on the Services may change from time to time, and
              Cashii may remove support for, or the ability to buy, sell,
              deposit, transfer, earn, hold or withdraw any Crypto Assets based
              solely on our discretion.
            </p>
          </div>
          <div className="col-lg-12 ">
            <h2 className="footer-heading-00">Account Related Terms</h2>
            <p className="footer-link">
              To use the Services, you must sign up for a Cashii account the
              Account. You may not create more than one Account. We may, in our
              sole discretion and without prior notice or justification, refuse
              to open an Account for you, suspend (also called a “freeze”) or
              terminate your Account or otherwise restrict some features or
              permissions in your Account, for different reasons such as, but
              not limited to, i) to perform a scheduled maintenance, ii) due to
              a non-compliance or potential non-compliance with the Terms, iii)
              for security, fraud, legal or reputational concerns or risks, iv)
              to conduct internal investigations, v) for regulatory or
              contractual reasons. You acknowledge that, if we do not
              communicate with you and you realize that your Account has been
              suspended or limited, it is your responsibility to reach out to
              our customer service team members. As part of the account opening
              process and from time to time afterwards (e.g. when we conduct
              investigations, expand our product offering, or we are required by
              law), you will be asked to provide information about yourself. You
              agree to always provide complete, truthful and accurate
              information, as well as authentic documents, when opening an
              Account or when interacting with us. You are also expected to
              promptly and proactively update your information, including but
              not limited to your residential address, email address and
              telephone number, to maintain their integrity and accuracy. You
              understand and agree that, from time to time, Cashii may require
              you to submit additional information about yourself or complete
              further verification steps, and that failure to comply may
              preclude your continued use of or access to the Services. You
              acknowledge that you are responsible for securing your Account,
              your Crypto Assets and your fiat currencies by adopting security
              practices and measures designed to protect your Account and
              related personal information. Namely, you agree: <br />
              <ul className="jgjtjtjthjhujt">
                <li className="footer-link">
                  <p>
                    To create, when required by Cashii a unique and strong
                    password that complies with Cashii’s password requirements;
                  </p>
                </li>
                <li className="footer-link">
                  <p>
                    To maintain access and keep secure, private and confidential
                    any security token or device required for authentication to
                    your Account, including any email address or phone number
                    that you may use for two-factor authentication;
                  </p>
                </li>
                <li className="footer-link">
                  <p>
                    To strictly follow all security procedures and guidelines
                    that may be issued by Cashii from time to time;
                  </p>
                </li>
                <li className="footer-link">
                  <p>
                    To immediately notify us and suspend your Account if you
                    discover or otherwise suspect that the security of your
                    Account has been compromised;
                  </p>
                </li>
                <li className="footer-link">
                  <p>Not to share your Account credentials with anyone else;</p>
                </li>
                <li className="footer-link">
                  <p>
                    Not to allow any third party (as agent or otherwise) to use
                    your Account;
                  </p>
                </li>
              </ul>
              Not to use your Account for the benefit of any third party. You
              are responsible for all activities and transactions that occur
              under your Account (including the amount of the transaction or the
              External Account or External Wallet (as defined below) to which
              you send your Fiats or Crypto Assets), and accept all risks of any
              authorized or unauthorized access to your Account. Cashii assumes
              no liability for any loss or consequences caused by any authorized
              or unauthorized access to or use of your Account. For greater
              certainty, you authorize Cashii to act upon instructions given
              from within your Account to our customer service team members
              (including but not limited to the buying, selling, trading,
              withdrawing and transferring of Crypto Assets). You agree that we
              are, in respect of any instructions received from a person using
              your Account, entitled to assume that the person is you. You
              acknowledge that the access to the Services may be interrupted or
              may become unavailable for reasons out of our control (for
              example, during times of significant volatility or volume).{" "}
            </p>
          </div>
          <div className="col-lg-12 ">
            <h2 className="footer-heading-00">Consent</h2>
            <p className="footer-link">
              Except where otherwise permitted or required by law, we will
              obtain your informed consent to collect and retain your personal
              information. Express consent may be obtained verbally,
              electronically or in writing. Implied consent may be obtained
              through your use of a product or service, or when you approach us
              to obtain information, inquire about or apply for products or
              services from us. Cashii will seek express consent when the
              information is likely to be considered sensitive. In certain
              circumstances, personal information can be collected, used, or
              disclosed without the knowledge and consent of the individual. For
              example, legal, medical or security reasons may make it impossible
              or impractical to seek consent. When information is being
              collected for the detection and prevention of fraud or for law
              enforcement, seeking the consent of the individual might defeat
              the purpose of collecting the information. Moreover, Cashii may
              provide personal information to its lawyer or agent to collect a
              debt, comply with a subpoena, warrant or other court order,
              government institution requesting the information upon lawful
              authority, or as may be otherwise required by law. We may disclose
              your personal information to law enforcement or other regulatory
              agencies such as the Financial Transactions and Reports Analysis
              Centre (FINTRAC) based on regulatory and legal requirements
              applicable to Cashii. 3.1 Cashii will generally seek consent for
              the use or disclosure of the information at the time of
              collection. In certain circumstances, consent with respect to use
              or disclosure may be sought after the information has been
              collected but before use (for example, when Cashii wants to use
              information for a purpose not previously identified to you). 3.2
              Cashii will not, as a condition of the supply of a product or
              service, require an individual to consent to the collection, use,
              or disclosure of information beyond that required to fulfill the
              explicitly specified and legitimate purposes. 3.3 An individual
              may withdraw consent at any time, subject to legal or contractual
              restrictions and reasonable notice. Cashii will inform the
              individual of the implications of such withdrawal. In order to
              withdraw consent, an individual must provide notice to Cashii in
              writing by contacting the Privacy Officer through email at
              cashii.compliance@gmail.com. 3.4 With respect to personal
              information already collected by Cashii prior to the publication
              of this Privacy Policy, this Policy will constitute reasonable
              notice to Cashii current customers and employees of the purposes
              and uses for which such personal information has been collected.
              Should an individual object to these ongoing uses or disclosures,
              consent may be withdrawn upon providing notice to Cashii in
              writing and is subject to any legal requirements.
            </p>
          </div>
          <div className="col-lg-12 ">
            <h2 className="footer-heading-00">Eligibility </h2>
            <p className="footer-link">
              To be eligible to use the Services, you must i) reside in Canada
              ii) be at least 18 years old, iii) have the legal capacity to hold
              an Account and accept the Terms. For clarity, residents of other
              countries are not permitted to use the Services. There are certain
              features or products which may or may not be available to you
              depending on your physical location (e.g. if you are outside of
              Canada) and other criteria. To be eligible to use the Services,
              you must not i) be included in any sanctions list in Canada or any
              other country, including any trade embargoes or economic sanctions
              list, the list of Specially Designated Nationals (“SDNs”) and
              Blocked Persons maintained by the Office of Foreign Assets Control
              of the U.S. Treasury Department or the denied persons or entity
              list of the U.S. Department of Commerce. If you do not, or no
              longer, meet the eligibility requirements mentioned above, you
              shall immediately inform Cashii and cease using the Services.
            </p>
          </div>
          <div className="col-lg-12 ">
            <p className="footer-link">
              <h2 className="footer-heading-00"> Prohibited Uses</h2>
              When accessing or using the Services, you agree that you will not
              violate any law, contract, intellectual property or other
              third-party right or commit a tort, and that you are solely
              responsible for your conduct while using our Services. Without
              limiting the generality of the foregoing, you agree that you will
              not: <br />
              a. use our Services in any manner that could interfere with,
              disrupt, negatively affect or inhibit other users from fully
              enjoying our Services, or that could damage, disable, overburden
              or impair the functioning of our Services in any manner; b. use
              our Services to pay for, support or otherwise engage in any
              illegal gambling activities, fraud, money-laundering, terrorist
              activities or other illegal activities; c. use any robot, spider,
              crawler, scraper or other automated means or interface not
              provided by us to access our attempt to circumvent any content
              filtering techniques we employ, or attempt to access any service
              or area of our Services that you are not authorized to access; f.
              develop any third-party applications that interact with our
              Services without our prior written consent; g. provide false,
              inaccurate, or misleading information; h.encourage or induce any
              third party to engage in any of the activities prohibited listed
              in this section; i. use the Services to take advantage of or
              profit from any technical glitch, malfunction, failure, delay,
              default or security breach; j. impersonate another person; k.
              violate the Terms; <br /> l. commit fraud; <br /> m. collude with
              others to help them commit fraud; or n. conduct any other illegal{" "}
              activities such as money laundering and/or terrorist financing. If
              Cashii determines, at its sole discretion, that you may have
              violated your obligations or the prohibitions as listed above, the
              Terms or its policies including our Anti-Money
              Laundering/Terrorist Financing policies, we may take actions
              without your consent, prior notice or justification, including
              without being limited to: i restricting your rights and
              permissions on the Services, ii blocking, freezing, suspending or
              closing your Account or any order requests or transactions, iii
              requiring you to promptly liquidate your Crypto Assets, i
              requiring that you provide us with fiat currency wire transfer
              instructions to a Canadian financial institution, and v reporting
              you to our banking partners, service providers, relevant financial
              intelligence agency, securities regulators, or law enforcement
              authorities.
              <h2 className="footer-heading-00 pt-3">Identity Verification</h2>
              During the sign-up process of your Account, or at any other time
              deemed necessary by Cashii, you agree to provide us with, and
              consent to the processing of, any personal information we may
              request for the purposes of identity verification, providing
              Services to you, the detection of money laundering, terrorist
              financing, fraud, any other financial crimes, and other lawful
              purposes. For more information, you can refer to our Privacy
              Policy. You can withdraw your consent at any time by closing your
              Account with us. However, we will retain and continue to process
              your personal information for as long as your Account is active
              and for a limited period of time after, but only as necessary in
              order to comply with laws or regulations applicable to us or our
              partners. We reserve the right at all times to monitor, review,
              retain and/or disclose any information so collected as necessary
              to satisfy any applicable law, regulation, sanctions programs,
              legal process or governmental request.
              <h2 className="footer-heading-00 pt-3">
                Custody of Crypto Assets and Fiat
              </h2>
              Cashii does not custody any Crypto Assets on behalf of customers.
              All Crypto Assets are held in self-custody wallets that are
              created and controlled exclusively by the user. You are solely
              responsible for the safekeeping of any recovery passwords,
              recovery phrases or other private information or data that may be
              needed to either access or recover a Crypto Asset wallet.
              Similarly, you are responsible for initiating any Crypto Asset
              transaction through your self-custody wallet. You acknowledge that
              Cashii does not custody your Crypto Assets nor does Cashii have
              any ability to send or receive Crypto Assets on your behalf. We
              may have the ability to block certain outgoing transactions before
              they are sent from your self-hosted wallet due to sanctions or
              other legal concerns. The Canadian dollar amounts in your Cashii
              Account are not bank deposits and not protected by the Canadian
              Investor Protection Fund, the Canadian Deposit Insurance
              Corporation or any other investor protection insurance program.
              Any Fiat you hold in your Cashii Account is your “credit balance”.
              This Fiat is payable to you on demand. It is segregated from
              Cashii s assets and represents our indebtedness to you. You
              acknowledge that the relationship between you and Cashii with
              respect to any Fiat balance is one of debtor and creditor.{" "}
              <h2 className="footer-heading-00 pt-3">
                Third Party Services Providers
              </h2>
              You acknowledge and agree that we may use third party service
              providers that we choose to assist with providing different
              aspects of the Services, including to hold your credit balance.
              Cashii is not affiliated or associated with any third party
              service providers relied upon that is not an official Cashii
              Affiliate. We do not endorse, approve or guarantee the accuracy of
              any content provided by a third-party service provider and make no
              representations or warranties about any third party service
              provider relied upon. Limited License We grant you a limited,
              non-exclusive, non-transferable license, subject to the Terms, to
              access and use the Services and all related content, materials and
              information collectively, the Content solely for approved purposes
              as permitted by Cashii from time to time, and solely for your own
              personal use and not on behalf of any third party. Any other use
              of the Services or Content is expressly prohibited and all other
              right, title and interest in the Services and Content is
              exclusively the property of Cashii.
              <h2 className="footer-heading-00 pt-3">Third-Party Content</h2>
              In using our Services, you may view content provided by third
              parties, including links to web pages of such parties, including
              but not limited to Facebook and Twitter links Third-Party Content.
              We do not control, endorse or adopt any Third-Party Content and
              shall have no responsibility for Third-Party Content, including
              without limitation material that may be misleading, incomplete,
              erroneous, offensive, indecent or otherwise objectionable. In
              addition, your business dealings or correspondence with such third
              parties are solely between you and the third parties. We are not
              responsible or liable for any loss or damage of any sort incurred
              as the result of any such dealings, and you understand that your
              use of Third-Party Content, and your interactions with third
              parties, is at your own risk.
              <h2 className="footer-heading-00 pt-3">
                Waiver; Limitation of Liability{" "}
              </h2>
              You acknowledge that buying and selling Crypto Assets is risky.
              You should only purchase Crypto Assets if you can afford to lose
              the entire amount of your purchase. You acknowledge the risk that
              the value of the Crypto Assets you hold could decrease to nil. You
              further acknowledge that there is a risk of malicious cyberattacks
              against the Services, which may result in the loss or theft of
              your Funds. Any up-to-date market information, including quotes or
              charts that we provide you is provided as is and where is without
              representations or warranties of any kind, and may contain
              typographical errors, be incomplete or inaccurate. While we may
              correct any such errors, missing information or inaccuracies, we
              are under no obligation to do so. We make no representation or
              warranty as to the ongoing availability of the Services. Although
              we strive to provide uninterrupted Services, we do not guarantee
              the absence of interruptions. Outages and downtime may occur for a
              variety of reasons. THE SERVICES AND CRYPTO ASSETS PURCHASED VIA
              THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
              CASHII CANNOT AND DOES NOT GUARANTEE, AND DOES NOT MAKE ANY
              REPRESENTATIONS OR WARRANTIES, AND, TO THE FULLEST EXTENT
              PERMITTED BY LAW, EXPRESSLY DISCLAIMS ALL REPRESENTATIONS,
              WARRANTIES, COVENANTS AND CONDITIONS OF ANY KIND, WHETHER EXPRESS
              OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING: (I) ANY WARRANTY OF
              MERCHANTABILITY; (II) FITNESS FOR A PARTICULAR PURPOSE; (III)
              NON-INFRINGEMENT; (IV) THAT THE SERVICES OR ANY CRYPTO ASSETS
              PURCHASED VIA THE SERVICES WILL MEET YOUR REQUIREMENTS; (V) THAT
              THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE;
              AND (VI) THAT ANY DEFECTS WITH THE SERVICES, IF ANY, WILL BE
              CORRECTED. YOU UNDERSTAND THAT ANY USE OF, MODIFICATION TO OR
              RELIANCE ON THE SERVICES, OR ANY CRYPTO ASSETS PURCHASED OR USED
              VIA THE SERVICES, IS AT YOUR OWN DISCRETION AND RISK. We will only
              be liable to you for direct damages resulting from any gross
              negligence or willful misconduct on our part arising directly from
              our performance and our responsibilities under the Terms of Use.
              Except in accordance with, and without limiting the generality of,
              the foregoing, you expressly acknowledge and agree that we will
              otherwise have no liability or responsibility whatsoever for any
              direct, indirect, special, punitive or consequential damages or
              loss however caused, arising out of your use of the Services,
              including, but not limited to: (i) losses resulting from
              fraudulent or unauthorized transactions; (ii) losses related to
              the installation, use or maintenance of personal computer,
              equipment, software, or caused by any worms, bugs, viruses, trojan
              horses, date bombs, defects, time bombs or other items of a
              destructive nature which may be transmitted to or using the
              Services; (iii) any third-party claims or losses of any nature,
              including lost profits, punitive or consequential damages; (iv)
              losses related to any errors, mistakes, inaccuracies or omissions
              in the Services; (v) losses relating in any way to, including
              errors in the reporting of, your taxes; (vi) losses relating in
              any way to a variation in an order; or (vii) losses as resulting
              from scheduled or unscheduled outages or disruptions that delay or
              prevent orders or the use of the Services generally.
              <h2 className="footer-heading-00 pt-3">Force Majeure </h2>
              We are not responsible or liable for any damages or loss resulting
              from causes outside our direct control, such as failure of
              electronic or mechanical equipment or communication lines,
              electronic viruses or malware, unauthorized access to our systems,
              theft, operator error, severe weather, natural disasters, strikes
              or other labour problems, wars, disease, epidemic, pandemic,
              governmental restrictions or for any loss of information caused by
              disruptions in or malfunctions of the Services.
              <h2 className="footer-heading-00 pt-3">Indemnification </h2>
              You agree to indemnify and hold harmless Cashii and its respective
              directors, officers, employees, agents, contractors,
              representatives, shareholders, successors and permitted assigns
              against any and all losses, damages, claims and liabilities
              (including reasonable legal fees) which may arise from your use of
              the Services (collectively the “Liabilities” and each a
              “Liability”). Without limiting the foregoing, this shall include:
              (a) any violation by you of the Terms; (b) any violation by you of
              a third party right; (c) any breach of a representation or
              warranty made by you to us, either in the Terms or otherwise; (d)
              any breach by you of applicable laws, regulations, by-laws, rules,
              policies and customs (collectively, “Applicable Rules and
              Regulations”); (e) any claim for damages brought against us by any
              financial institution, related to your non-compliance with the
              Terms; or (g) any and all expenses incurred by us in connection
              with exercising any right pursuant to this section. If you fail to
              pay any Liability or if you fail to comply with any other
              requirement contained in the Terms, then, in addition to any other
              right or remedy to which Cashii is entitled, we may at any time
              and from time to time, without notice or demand to you, apply some
              or all of your Fiat to eliminate or reduce the Liability or other
              amount, as the case may be. You shall remain liable to Cashii for
              any deficiency remaining following the exercise by us of any or
              all of our rights under these Terms and agree that the rights
              which we are entitled to exercise are reasonable and necessary for
              Cashii’s protection.{" "}
              <h2 className="footer-heading-00 pt-3">General</h2>
              Governing Law. The Terms shall be g overned by, and construed in
              accordance with, the laws of the Province of Ontario and the
              federal laws of Canada applicable therein. Any legal suit, action
              or proceeding arising out of or relating to the Terms shall be
              instituted in the courts of Toronto, Ontario and each party
              irrevocably submits to the exclusive jurisdiction of such courts
              in any such action or proceeding. 29. Electronic Delivery of
              Information. Any alerts, notices, transaction confirmations or
              communications relating to your Cashii Account will be provided
              electronically, either via the Services or to the email address
              you provide to us. Assignment. You will not assign your rights or
              obligations hereunder to any third party without our prior written
              consent; provided however, that no assignment may relieve you of
              any of your obligations hereunder. Any attempted assignment,
              transfer or other conveyance in violation of the foregoing will be
              void. We may assign any rights or obligation under the Terms to
              any affiliate or third party without your consent. The Terms are
              binding upon and will enure to the benefit of each of the parties,
              and each of their permitted successors and permitted assignees.
              31. Headings. The headings in the Terms are for reference only and
              do not affect the interpretation of the Terms. Severability. If
              any term or provision hereof is held to be invalid, illegal or
              unenforceable, such invalidity, illegality or unenforceability
              will only apply to such provision. The validity of the rest of the
              Terms will not be affected. The Terms will continue to be carried
              out as if such invalid or unenforceable provision were not in the
              Terms. If any Applicable Rules and Regulations are enacted, made,
              amended or otherwise changed with the result that any term or
              condition hereof is, in whole or in part, invalid, then such term
              or condition will be deemed to be varied or superseded to the
              extent necessary to give effect to such Applicable Rules and
              Regulations. Survival. The Terms will survive and remain in effect
              notwithstanding any incidental, temporary or intermittent closing
              out, reopening or renumbering of your Cashii Account. Entire
              Agreement. The Terms will be read in conjunction with any other
              agreements between you and Cashii in connection with your Cashii
              Account, provided that, to the extent necessary, the terms and
              provisions hereof shall supersede the terms and provisions of all
              other agreements with Cashii, whether or not referred to herein,
              except that the Terms in no way limit or restrict any other rights
              which Cashii may have under any other agreement with you. No
              Waiver. No waiver by either party of any of the provisions hereof
              is effective unless explicitly set forth in writing. No waiver by
              either party shall operate or be construed as a waiver in respect
              of any failure, breach or default not expressly identified by such
              written waiver, whether of a similar or different nature, and
              whether occurring before or after that waiver. No failure to
              exercise, or delay in exercising, any right, remedy, power or
              privilege arising from the Terms shall operate or be construed as
              a waiver thereof; nor shall any single or partial exercise of any
              right, remedy, power or privilege hereunder preclude any other or
              further exercise thereof or the exercise of any other right,
              remedy, power or privilege.{" "}
            </p>
          </div>
          {/* for mobile */}
        </div>
        {/* for moblie */}
      </div>
    </div>
  );
}

export default TermsUse;
