import React from "react";

function Privatesecurewallet() {
  return (
    <div className="position-relative">
      <img
        className="main-hero-image-004"
        src="./assets/img/sec2.png"
        alt="Logo"
      />
      <img
        className="main-hero-image-005"
        src="./assets/img/sec3.png"
        alt="Logo"
      />
      <img
        className="main-hero-image-006"
        src="./assets/img/sec4.png"
        alt="Logo"
      />
      <div className="container">
        <div className="row mian-spacing-002">
          <div className="col-lg-6 order-lg-2 order-2">
            <div className="position-relatvie">
              <div className="d-flex justify-content-center gtrhthytyth6ythythyt">
                <img
                  style={{ cursor: "pointer" }}
                  className="for-rotating"
                  src="./assets/img/private.png"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-center order-lg-2 order-1">
            <div className="nbvjevroiv896567">
              <h3 className="cashii-main-heading text-start">
                Private & Secure Wallet
              </h3>
              <p className="cashii-main-text text-start">
                Cashii offers private and secure self-custodial wallet and
                complete control over your funds. Cashii wallet allows
                individuals to store their cryptocurrencies securely without
                relying on third-party services. By holding their keys, users
                can ensure the privacy and security of their transactions
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privatesecurewallet;
