import React from "react";
import { Link } from "react-router-dom";

export default function Rewards() {
  return (
    <div>
      <div className="">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-5  mainsection-0988899">
                  <h3 className="finalsectiion-09098">Regulated by Fintrac</h3>
                  <p className="section-0999 pt-3">
                    Cashii Powered By YATO INC is <br /> Regulated By Fintrac
                  </p>
                  {/* <p className="section-0999">
                    MSB registration number: M23869060
                  </p> */}
                  <p className="section-0999">
                    MSB registration number: M23869060
                  </p>
                  <div className="d-flex justify-content-center">
                    <p className="footer-link">
                      <Link to="/termsandconditions" target="_blank">
                        {" "}
                        Terms & Conditions |
                      </Link>
                      <Link to="/privacy" target="_blank">
                        {" "}
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <img
                      className="tergh667588787"
                      src="./assets/img/333.png"
                      alt="Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
